import HomeLayout from "src/Vistas/LayOuts/HomeLayout.vue";
import VistasAuthLayout from "src/Vistas/LayOuts/AuthLayout.vue";
// GeneralViews
import PaginaNoEncontrada from "src/Vistas/Main/PaginaNoEncontrada.vue";

let authVistas = {
  path: "/",
  component: VistasAuthLayout,
  name: "Authentication",
  children: [

    {
      path: "/login",
      name: "Inicio de sesión",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Main/InicioSesion.vue"
        ),
    },
  ]
}
// GeneralViews
const routes = [
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/pages/Slider/Anuncios.vue"),
  },
  {
    path: "/clientes/:id/configuracion/",
    name: "Configuracion",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/pages/Slider/Configuracion.vue"),
  },
  {
    path: "/edicion",
    name: "edicion",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/pages/Slider/Edicion.vue"),
  },
  {
    path: "/",
    name: "Default",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/Vistas/Main/Default.vue"),
  },
  
  authVistas,
  // Agregar código para mostrar vacio
  { path: "*", name: "Página no encontrada", component: PaginaNoEncontrada },
];

export default routes;
