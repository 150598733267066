/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import "regenerator-runtime/runtime";
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";

// router setup
import router from "./routes/router";
import i18n from "./i18n";

//import './registerServiceWorker'
import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

//FontAwesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Vuelidate from "vuelidate";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
extend("email", email);
//extend("phone", phone);
extend("required", required);
extend("min", min);
/*
import axios from 'axios'
import VueAxios from 'vue-axios'
*/
import { localize } from "vee-validate";

// Install English and Arabic locales.
localize("es");
//aplication Insights
import VueAppInsights from "vue-application-insights";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(Vuelidate);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import Base from "@/api-services/base";
import MsalService from "@/api-services/msal.service";
import VueSignalR from "@latelier/vue-signalr";

import { default as msal, CachePolicyLookup } from "vue-msal-browser";

Vue.use(msal, MsalService.config);

import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";

Vue.prototype.$SuscripcionesUsuarios = SuscripcionesUsuarios;
Vue.prototype.$Prueba = { Parametro: "23456" };
Vue.prototype.$PerfilLocal = { usuario: {}, propiedades: {} };
//Vue.use(VueSignalR, Base.SignalRURL);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.use(VueAppInsights, {
  id: "3854059a-d5d3-4816-b4ee-67e48334c8f7",
  router,
  appInsightsConfig: {
    accountId: "-1",
    loggingLevelConsole: 2,
    autoTrackPageVisitTime: true,
  },
  accountId: -1,
});

//http://localhost:8080/#/empleado
///Redirigir a Login si no ha iniciado sesión
router.beforeEach((to, from, next) => {
  console.debug(from);
  const currentUser = localStorage.sesion;
  console.debug(to);
  var requiresAuth =
    to.matched.some((record) => record.meta.requiresAuth) ||
    //to.path.indexOf("/state=") == 0 ||
    to.path == "/login";
  //Mueve al inicio el scroll al cambiar de página
  const container = document.querySelector(".main-panel");
  if (container) container.scrollTop = 0;

  if (requiresAuth)
    requiresAuth = currentUser == null || currentUser == "" || Base.EsSSO == 1;

  if (!requiresAuth || !Base.EsSSO) {
    next();
    return;
  }
  MsalService.handleRedirectPromise(Vue.prototype.$msal, next);

  return;
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  // store,
  i18n,
}); //.$mount('#app');

localize({
  es: {
    messages: {
      // generic rule messages...
    },
    fields: {
      fullname: {
        required: "Requerimos tu nombre completo",
      },
      whatsapp: {
        required: "Requerimos tu número de celular",
        min: "A 10 digitos, te enviaremos un SMS para validarlo",
      },
      email: {
        required:
          "Requerimos tu correo electrónico, lo validaremos enviandote un email",
      },
      password: {
        required: "Se requiere contraseña",
        min: "La contraseña debe ser mínimo de {length} caracteres",
      },
    },
  },
});
