<template>
  <footer class="footer">
    <div class="container-fluid">
        <ul class="nav">
          <li class="nav-item">
            <a
            style="font-size: 1vw;"
              href="https://www.eClock.com.mx"
              target="_blank"
              rel="noopener"
              class="nav-link"
            >
              eClock.com.mx
            </a>
          </li>
        </ul>
        <!-- <i style="color: white; margin-top: 1vh; cursor: pointer; font-size: 1vw;" class="fas fa-qrcode ml-1" @click="VerQrModal"></i> -->
      <div style="font-size: 1vw;" class="copyright text-wrap" @click="OnClick">
        <i style="color: white; margin-top: 1vh; cursor: pointer; font-size: 1vw; margin-right: 1vw;" class="fas fa-qrcode ml-1" @click.stop="VerQrModal"></i>
        &copy; {{ year }}, creado con
        <i class="tim-icons icon-heart-2"></i> para valorar, gestionar e incentivar.
        {{ Datos }}
      </div>
      <CreateQR v-if="QrModal" :Mostrar="QrModal" :Url="urlconfig" @Actualiza="VerQrModal" />
    </div>
  </footer>
</template>
<script>
import BaseService from "@/api-services/base.service";
import Base from "@/api-services/base";
import Mantenimiento from "@/api-services/mantenimiento";
import { sVersion } from "@/api-services/fechaPublicacion";
import swal from "sweetalert2";
import CreateQR from '../../pages/Components/Slider/Modales/CreateQR.vue';

export default {
  components: {CreateQR,},
  data() {
    return {
      Version: Base.Version,
      year: new Date().getFullYear(),
      Datos: "",
      QrModal:false,
      cargando: false,
      IDSlide: null
    };
  },
  computed: {
    urlconfig(){
      let perfil = JSON.parse(localStorage.getItem("perfilLocal"))
        let usuario = perfil.usuario.llaveProveedor
        let id = usuario.split("=")
        let url = window.location.href.split("/#")
        this.IDSlide = id[1]
        // return url[0] +  `/clientes/${id[1]}/configuracion`
        return `https://gob.eclock.com.mx/clientes/${id[1]}/configuracion`
    },
  },
  mounted() {
    BaseService.CabiarTema();
  },
  methods: {
    OnClick() {
      if (this.cargando) return;
      Mantenimiento.Version(this, (response) => {
        // html: `Go: <b>${BaseService.Version}</b> <br>
        swal.fire({
          title: "Versión",
          html: `Slider: <b>${sVersion}</b> <br>
          Api: <b>${response.data}</b> <br>
          ID: <b>${this.IDSlide}</b>`,

      //    text: `Go: ${BaseService.Version} - Api: ${response.data}`,
          //icon: 'success',
          iconHtml: '<i class="fas fa-seedling"></i>',
          showCancelButton: false,
          customClass: {
            confirmButton: "btn ",
          },
          confirmButtonText: "Ok",
          buttonsStyling: false,
        });
      });
      /*
        if (!BaseService.IsProd())s
          this.Datos = `${localStorage.suscripcionID}/${localStorage.sesion}`*/
    },
    VerQrModal(){
      this.QrModal = !this.QrModal
    },
  },
};
</script>
<style></style>
