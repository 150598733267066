<template>
    <modal :show.sync="Modal" class="modal-black" modalContentClasses="card" headerClasses="card-header">
        <div slot="header">
            <h3 class="card-title">Configuracion</h3>
        </div>
        <template>
            <div class="text-center" ref="QRprint">
                <div class="d-flex justify-content-center m-4">
                    <VueQr :text="Url" :size="2600" :correctLevel="3" logoSrc="img/e512transparente.png"
                        qid="https://www.eClock.com.mx" width="70%" />
                </div>
            </div>
        </template>
    </modal>
</template>
  
<script>
import VueQr from "vue-qr";

export default {
    components: { VueQr },
    name: "CreateQR",

    data() {
        return {
            Modal: false,
        };
    },

    props: {
        Url: { type: String, default: null },
        Mostrar: { type: Boolean, default: false },
    },

    watch: {
        Mostrar(Mostrar) {
            this.$appInsights.trackEvent(
                { name: this.$options.name },
                { Mostrar: Mostrar }
            );
            if (Mostrar) {
                this.Modal = true;
            } else this.Modal = false;
        },
        Modal(Modal) {
            if (!Modal)
            this.$emit("Actualiza", Modal);
        },
    },
    computed: {},

    mounted() {
        this.Modal = this.Mostrar;
    },
    created() { },
    methods: {
        
    },
};
</script>
  
<style scoped>
.vti__dropdown-list {
    background-color: #000000 !important;
}

.grid {
    display: grid;
}
</style>
  